exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-categories-cacti-js": () => import("./../../../src/pages/categories/cacti.js" /* webpackChunkName: "component---src-pages-categories-cacti-js" */),
  "component---src-pages-categories-care-js": () => import("./../../../src/pages/categories/care.js" /* webpackChunkName: "component---src-pages-categories-care-js" */),
  "component---src-pages-categories-flowers-js": () => import("./../../../src/pages/categories/flowers.js" /* webpackChunkName: "component---src-pages-categories-flowers-js" */),
  "component---src-pages-categories-fruits-js": () => import("./../../../src/pages/categories/fruits.js" /* webpackChunkName: "component---src-pages-categories-fruits-js" */),
  "component---src-pages-categories-herbs-js": () => import("./../../../src/pages/categories/herbs.js" /* webpackChunkName: "component---src-pages-categories-herbs-js" */),
  "component---src-pages-categories-veggies-js": () => import("./../../../src/pages/categories/veggies.js" /* webpackChunkName: "component---src-pages-categories-veggies-js" */),
  "component---src-pages-culinary-js": () => import("./../../../src/pages/culinary.js" /* webpackChunkName: "component---src-pages-culinary-js" */),
  "component---src-pages-design-js": () => import("./../../../src/pages/design.js" /* webpackChunkName: "component---src-pages-design-js" */),
  "component---src-pages-houseplant-identification-js": () => import("./../../../src/pages/houseplant-identification.js" /* webpackChunkName: "component---src-pages-houseplant-identification-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-links-js": () => import("./../../../src/pages/links.js" /* webpackChunkName: "component---src-pages-links-js" */),
  "component---src-pages-posts-js": () => import("./../../../src/pages/posts.js" /* webpackChunkName: "component---src-pages-posts-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-sustainability-js": () => import("./../../../src/pages/sustainability.js" /* webpackChunkName: "component---src-pages-sustainability-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

